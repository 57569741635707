import './FleetTracking.scss';
import Card from 'components/chrome/Card';
import React from 'react';
import TextToParagraph from 'components/TextToParagraph';
import screenshot_fleet from 'img/screenshot_fleet_2.png';
import {Button} from 'primereact/button';
import {Link} from 'react-router-dom';


const content = {
  title: 'Fleet Tracking',
  image: screenshot_fleet,
  cards: [
    {
      title: 'Compare user segments',
      description: 'Comparisons across segments show how users engage with their devices differentially. Make use of built-in segments, such as health risk level, as well as your own custom segments.',
    },
    {
      title: 'Re-engage at the right time',
      description: 'Compliance with expected device usage improves patient outcomes, reducing hospital admissions and improving care. Identify activities that precede disengagement to better time interventions.',
    },
    {
      title: 'Custom events',
      description: 'Define and track events that matter for your device, whether specific to a particular indication, or monitoring purpose.',
    },
    {
      title: 'CPT code workflow',
      description: 'Quickly determine which of your devices are eligible for reimbursement codes each cycle. Maximize your earnings from payers with accurate, verifiable data.',
    },
  ],
  demoLink: 'https://forms.gle/cag75L3nc7wd1hsQ8',
};


function FleetTracking() {
  return (
    <div className="page-fleettracking">
      <div className="section">
        <div className="subtitle">
          {content.title}
        </div>
        <TextToParagraph text={content.description} />
        <p>
          Once your devices are deployed into the hands of consumers, it can be difficult to keep watch on them in constructive ways. Instrument them with our fleet tracking API, and this becomes a lot easier. From the top level
          —
          <span className='hypothetical'>Who are our most active users? Where are they located? Are our sync times getting slower?</span>
          —
          and drilling down into smaller segments
          —
          <span className='hypothetical'>Is cohort A more engaged than cohort B? Does a charging notification increase wear time? Does this hardware version result in a higher RMA rate?</span>
          —
          the insights you collect translate into confident data-powered action.
        </p>
      </div>
      <div className="image-section-wrapper">
        <div className="section image">
          <img src={content.image} alt="Fleet" />
        </div>
      </div>
      <div className="section">
        <div className='p-grid cards'>
          {content.cards.map(card => (
            <div className='p-col-12 p-lg-6'>
              <Card
                  direction='horizontal'
                  header={card.title}
                  text={card.description} />
            </div>
          ))}
        </div>
        <div className="cta">
          <Button className='ctaButton'
              label='Request a demo'
              icon='pi pi-star' iconPos='right'
              onClick={() => window.open(content.demoLink, '_blank')} />
          <Link to="/platform">
            <Button className="ctaButton"
                label="Learn more"
                icon="pi pi-arrow-right" iconPos="right" />
          </Link>
        </div>
      </div>
    </div>
  );
}


export default FleetTracking;
