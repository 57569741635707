import './Footer.scss';
import React from 'react';


class Footer extends React.Component {

  render() {
    return (
      <div className="Footer">
        <div className="top"></div>
        <div className="content">
          <div className="left">
            &copy; 2020 Axle Medical
          </div>
          <div className="right">
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
