import './Platform.scss';
import React from 'react';
import screenshot_begin from 'img/screenshot_begin_3.png';
import {Button} from 'primereact/button';
import {Link} from 'react-router-dom';



const content = {
  title: 'Full Platform',
  p1: "Connectivity software doesn't need to be as hard as it is today—which is why we're working to solve this. The Axle Medical platform shifts the equation from building backend software in-house to connecting with our stack instead.",
  p2: "Get everything you need, but don't have the time to build—HIPAA and GDPR compliance, strong encryption and authorization protocols, device configuration, high availability, disaster recovery, region control—all wrapped in a virtual private cloud dedicated to only your devices.",
  p3: 'Worried about being boxed in by a one-size-fits-all? The platform is designed with custom hooks for triggers, alerts, algorithms, and other custom behaviors, so your magic and our platform mesh seamlessly.',
  p4: 'When we build together, patients win.',
  p5: 'Our full platform is currently under development. Contact us for an initial consultation as to whether our platform will work for you, when the time is right.',
  image: screenshot_begin,
};


function Platform() {
  return (
    <div className="page-platform">
      <div className="section">
        <div className="subtitle">
          {content.title}
        </div>
        <p>{content.p1}</p>
        <p>{content.p2}</p>
        <p>{content.p3}</p>
        <p style={{fontWeight: 'bold'}}>{content.p4}</p>
        <p>{content.p5}</p>
      </div>
      <div className="image-section-wrapper">
        <div className="section image">
          <img src={content.image} alt="Platform" />
        </div>
      </div>
      <div className="section">
        <div className="cta">
          <Link to="/about">
            <Button className="ctaButton"
                label="Learn more"
                icon="pi pi-arrow-right" iconPos="right" />
          </Link>
        </div>
      </div>
    </div>
  );
}


export default Platform;
