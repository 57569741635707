import React from 'react';
import { Background, Parallax } from "react-parallax";


const DEFAULT_HEIGHT_PX = 100;
const DEFAULT_Y_OFFSET_PX = 0;


class ParallaxImage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const extraClasses = this.props.className || '';
    const heightPx = this.props.heightPx || DEFAULT_HEIGHT_PX;
    const yOffsetPx = this.props.yOffsetPx || DEFAULT_Y_OFFSET_PX;
    const style = {
      background: `url(${this.props.src})`,
      backgroundPosition: `center ${yOffsetPx}px`,
      height: `${2 * heightPx}px`,
      width: this.state.width,
    };

    return (
      <Parallax
          className={`ParallaxImage ${extraClasses}`}
          style={{height: `${heightPx}px`}}
          strength={heightPx}>
        <Background>
          <div style={style} />
        </Background>
      </Parallax>
    );
  }
}

export default ParallaxImage;
