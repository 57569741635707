import './Login.scss';
import ParallaxImage from 'components/ParallaxImage';
import React from 'react';
import slide_pic_7 from 'img/slide_pic_7.png';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';


const hideIf = predicate => predicate ? 'hide' : '';


class Login extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const result = this.state.loginResult;

    return (
      <div className="page-login">
        <div className="section">
          <div className="subtitle">
            Dashboard login
          </div>
          <div className="login-inputs">
            <div className="p-float-label">
              <InputText
                  id="username-input"
                  type="text"
                  value={(this.state && this.state.username) || ''}
                  onKeyPress={e => e.which === 13 && this.handleLogin()}
                  onChange={e => this.setState({username: e.target.value})} />
              <label htmlFor="username-input">Username</label>
            </div>
            <div className="p-float-label">
              <InputText
                  id="password-input"
                  type="password"
                  value={(this.state && this.state.password) || ''}
                  onKeyPress={e => e.which === 13 && this.handleLogin()}
                  onChange={e => this.setState({password: e.target.value})} />
              <label htmlFor="username-input">Password</label>
            </div>
            <Button
                label="Login" icon="pi pi-chevron-right" iconPos="right"
                onClick={this.handleLogin.bind(this)} />
            <p className={`success ${hideIf(result !== 'success')}`}>
              Redirecting to dashboard, please wait...
            </p>
            <p className={`failure ${hideIf(result !== 'failure-network')}`}>
              A network error occurred, please try again later.
            </p>
            <p className={`failure ${hideIf(result !== 'failure-invalid')}`}>
              Invalid login. Please contact support if you need assistance
              logging in.
            </p>
          </div>
        </div>
        <ParallaxImage src={slide_pic_7} heightPx={240} speed={1} />
      </div>
    );
  }

  handleLogin() {
    if (!this.state.username || !this.state.password) {
      this.setState({loginResult: null});
      return;
    }
    fetch( '/login', {
      method: 'POST',
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
      })
    })
    .then(rsp => {
      if (rsp.status === 302) {
        this.setState({loginResult: 'success'});
      } else {
        this.setState({loginResult: 'failure-invalid'});
      }
    })
    .catch(err => {
      console.log(err);
      this.setState({loginResult: 'failure-network'});
    });
  }
}


export default Login;


