import './App.scss';
import 'styles/media.css';
import 'primeflex/primeflex.scss';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import Footer from 'components/Footer.js';
import About from 'page/About';
import FirmwareOta from 'page/FirmwareOta';
import FleetTracking from 'page/FleetTracking';
import Home from 'page/Home';
import Login from 'page/Login';
import Platform from 'page/Platform';
import React from 'react';
import ReactGa from 'react-ga';
import ScrollToTop from 'components/ScrollToTop';
import Topnav from 'components/Topnav.js';
import {BrowserRouter} from 'react-router-dom';
import {Route} from 'react-router';


const NAV_LINKS = [
  {component: Home, label: 'Home', path: '/'},
  {component: FirmwareOta, label: 'Firmware OTA', path: '/ota'},
  {component: FleetTracking, label: 'Fleet Tracking', path: '/fleet'},
  {component: Platform, label: 'Full Platform', path: '/platform'},
  {component: About, label: 'About'},
  {
    component: Login,
    label: 'Login',
    path: 'https://freedom.axle-medical.com/login',
  },
];


const getPageLocation = item => (
    item.path || '/' + item.label.replace(/ /g, '').toLowerCase());


const makePageRoute = item =>
    <Route 
        key={item.label}
        exact path={getPageLocation(item)}
        component={item.component} />;


const gaRoute = (
  <Route path="/" render={({location}) => {
      ReactGa.pageview(location.pathname + location.search);
      console.log(location.pathname + location.search);
      return null;
    }} />
);


const App = () =>
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          {window.location.href.indexOf('localhost') > -1 ? '' : gaRoute}
          <Topnav links={NAV_LINKS} />
          <div className="backdrop">
            <div className="content">
              {NAV_LINKS.map(makePageRoute)}
            </div>
          </div>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </div>;


ReactGa.initialize('UA-4516355-9');


export default App;
