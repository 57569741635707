import './Home.scss';
import Card from 'components/chrome/Card';
import ParallaxImage from 'components/ParallaxImage';
import React from 'react';
import slide_pic_5 from 'img/slide_pic_5.png';
import {Button} from 'primereact/button';
import {Link} from 'react-router-dom';


function Home() {
  return (
    <div className="page-home">
      <div className="header section">
        <div className="title center">
          Axle Medical
        </div>
        <div className="tagline center">
          Powering the world's connected medical devices
        </div>
        <div>
          <p>
            Our software helps you commercialize your device 3-6 months faster.
            It's simple – plug into our API from iOS, Android, web, or 5G, to leverage the power of your own virtual private cloud.
          </p>
        </div>
      </div>
      <ParallaxImage
          src={slide_pic_5}
          heightPx={240} yOffsetPx={-180} />
      <div className="section">
        <div className="subtitle">
          The platform designed specifically for medical devices
        </div>
        <p>
          Medical devices have specific needs that can only be met by secure, robust, integrated solutions designed with the full system in mind. Axle Medical is building the HIPAA-compliant platform ideal for supporting at-home and wearable devices with strong cybersecurity needs. 
        </p>
			</div>
			<div className='section'>
        <div className='p-grid cards'>
					<div className='p-col-12 p-lg-4'>
						<Card
              direction='horizontal'
              header='Less time, lower cost'
              text='Leveraging a plug-and-play software platform dramatically reduces development time, compared to building in-house.' />
					</div>
					<div className='p-col-12 p-lg-4'>
						<Card
              direction='horizontal'
              header="Security that's always up to date"
              text='Our security experts track threats, leaving you with one less risk to worry about.' />
					</div>
					<div className='p-col-12 p-lg-4'>
						<Card
              direction='horizontal'
              header="HIPAA? We've got that"
              text='Our software complies with all applicable regulations, so you can market your devices in the U.S. and Europe.' />
					</div>
        </div>
        <div className="cta">
          <Link to="/ota">
            <Button className="ctaButton"
                label="Learn more"
                icon="pi pi-arrow-right" iconPos="right" />
          </Link>
        </div>
      </div>
    </div>
  );
}


export default Home;
