import React from 'react';


const paragraphs = text => {
  if (typeof text === typeof []) {
    return text.map(paragraphs);
  }
  else {
    return <p key={Math.random()}>{text}</p>;
  }
}


class TextToParagraph extends React.Component {
  render() {
    const text = this.props.text;
    return (
      <div className={`TextToParagraph ${this.props.className}`}>
        {paragraphs(text)}
      </div>
    );
  }
}


export default TextToParagraph;
