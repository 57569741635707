import 'components/Topnav.scss';
import React from 'react';
import axle_logo_med_white from 'img/axle_logo_med_white.png';
import {Button} from 'primereact/button';
import {Link} from 'react-router-dom';
import {Sidebar} from 'primereact/sidebar';


class Topnav extends React.Component {
  constructor() {
    super();
    this.state = { sidebarVisible: false };
  }

  mkLink(label, path, className) {
    if (!path) {
      path = '/' + label.replace(/ /g, '').toLowerCase();
    }
    const isExternal = path.startsWith('http');
    if (isExternal) {
      return (
        <a
            key={label}
            href={path}
            target='_blank'
            rel='noopener noreferrer'
            onClick={e => this.setState({sidebarVisible: false})}>
          {label}
        </a>
      );
    }
    return (
      <Link
          key={label}
          to={path}
          className={className}
          onClick={(e) => this.setState({sidebarVisible: false})}>
        {label}
      </Link>
    );
  }

  render() {
    const links = this.props.links || [];
    return (
      <div className="Topnav sticky">
        <div className="left">
          <Link to="/">
            <img src={axle_logo_med_white} className="logo" alt="Axle logo" />
          </Link>
        </div>
        <div className="right">
          <div className="desktop">
            {links.map(i => this.mkLink(i.label, i.path, i.className))}
          </div>
          <div className="mobile">
            <Sidebar 
                position="right"
                className="sidebar"
                visible={this.state.sidebarVisible}
                onHide={(e) => this.setState({sidebarVisible: false})}>
              {links.map(i => this.mkLink(i.label, i.path, i.className))}
            </Sidebar>
            <Button
                className="sidebarButton"
                icon="pi pi-bars"
                onClick={(e) => this.setState({sidebarVisible: true})}/>
          </div>
        </div>
      </div>
    );
  }
}

export default Topnav;
