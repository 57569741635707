import './FirmwareOta.scss';
import Card from 'components/chrome/Card';
import React from 'react';
import TextToParagraph from 'components/TextToParagraph';
import screenshot_firmware from 'img/screenshot_firmware_3.png';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';


const content = {
  title: 'Firmware Over-the-Air Updates',
  description: 'Safe and reliable updates to device firmware are a critical element of any connected medical device, allowing you to add features after deployment and deliver critical security patches when needed. Getting OTAs right can be a challenge, as this exposes new cybersecurity points of attack, uncertainty around authenticity of firmware images, and logistical challenges in deployment and monitoring releases. Our OTA service addresses security, authenticity and deployment, all in an easy to understand environment, giving you the highest confidence in your capability to update remote devices.',
  image: screenshot_firmware,
  cards: [
    {
      title: 'Secure and verifiable keys',
      description: 'Images are signed by single-purpose keys stored in a key vault. Signature verification during the upgrade process ensures no bytes have been altered in transit.',
    },
    {
      title: 'Staged rollouts',
      description: 'Deploying firmware upgrades in progressive stages mitigates the risk of releasing a bad image. Control each stage of your rollout across your device fleet.',
    },
    {
      title: 'Safe rollbacks',
      description: 'Any firmware update comes with the associated risk that it may need to be rolled back. Rapid rollback creation and deployment gives you an additional mitigation tool.',
    },
    {
      title: 'Real-time monitoring',
      description: 'Watch progress of deployments when you release a new image. Our intuitive interface makes it easy to understand how the rollout is proceeding.',
    },
  ],
  demoLink: 'https://forms.gle/cag75L3nc7wd1hsQ8',
};


class FirmwareOta extends React.Component {
  constructor() {
    super();
    let params = new URLSearchParams(window.location.search);
    this.state = {
      overlayVisible: (!!params.get('ref') || !!params.get('selection')),
      selection: params.get('selection'),
    };
  }

  renderDialog() {
    return (
      <div className='dialogMainContent'>
        <div className='description'>
          Upload, deploy, and monitor firmware versions from the cloud. It's easy to get started, with a simple API and free trial period.
        </div>
        <div className='dialogInnerContent'>
          {this.state.selection ?
              this.renderForm(this.state.selection) :
              this.renderOptions()}
        </div>
        <div className='cta'>
          <Link to='/ota'>
            <Button
                className='ctaButton p-button-secondary p-button-raised'
                label='View website'
                onClick={() => this.setState({overlayVisible: false})}
                />
          </Link>
          <Button
              className='ctaButton p-button-secondary p-button-raised'
              label='Ask a question'
              onClick={() => {
                if (!window.drift) {
                  return;
                }
                window.drift.api.startInteraction(
                    { interactionId: 1933668, goToConversation: true });
              }} />
        </div>
      </div>
    );
  }

  renderOptions() {
    return (
      <div className='options p-grid'>
        <div className='optionWrapper p-col-12 p-sm-6 p-lg-3'>
          <div className='option'>
            <div className='optionHeader'>
              <div className='optionName'>Silver</div>
              <div className='freeTrial'>30-day free trial</div>
            </div>
            <div className='bullets'>
              <div className='bullet'>Managed security solution</div>
              <div className='bullet'>HTTPS API access</div>
              <div className='bullet'>Axle Key Vault image signing</div>
              <div className='bullet'>Up to 1000 devices</div>
              <div className='bullet'>Up to 1MB image size</div>
              <div className='bullet'>1 active firmware version</div>
              <div className='bullet'>$99/month (billed annually)</div>
            </div>
            <div className='cta'>
              <Link to='/ota?selection=Hosted Firmware Silver'>
                <Button className='ctaButton p-button-raised'
                    label='Select'
                    icon='pi pi-arrow-right' iconPos='right'
                    onClick={() => this.setState({selection: 'Hosted Firmware Silver'})}
                    />
              </Link>
            </div>
          </div>
        </div>
        <div className='optionWrapper p-col-12 p-sm-6 p-lg-3'>
          <div className='option highlight'>
            <div className='optionHeader'>
              <div className='optionName'>Gold</div>
              <div className='freeTrial'>30-day free trial</div>
            </div>
            <div className='bullets'>
              <div className='bullet'>Managed security solution</div>
              <div className='bullet'>HTTPS API access</div>
              <div className='bullet'>Android & iOS clients</div>
              <div className='bullet'>Axle Key Vault image signing</div>
              <div className='bullet'>Up to 10k devices</div>
              <div className='bullet'>Up to 3MB image size</div>
              <div className='bullet'>Up to 3 active firmware versions</div>
              <div className='bullet'>$199/month (billed annually)</div>
            </div>
            <div className='cta'>
              <Link to='/ota?selection=Hosted Firmware Gold'>
                <Button className='ctaButton p-button-raised'
                    label='Select'
                    icon='pi pi-arrow-right' iconPos='right'
                    onClick={() => this.setState({selection: 'Hosted Firmware Gold'})}
                    />
              </Link>
            </div>
          </div>
        </div>
        <div className='optionWrapper p-col-12 p-sm-6 p-lg-3'>
          <div className='option'>
            <div className='optionHeader'>
              <div className='optionName'>Platinum</div>
              <div className='freeTrial'>30-day free trial</div>
            </div>
            <div className='bullets'>
              <div className='bullet'>Managed security solution</div>
              <div className='bullet'>HTTPS API access</div>
              <div className='bullet'>Android & iOS clients</div>
              <div className='bullet'>Axle Key Vault or custom image signing</div>
              <div className='bullet'>Up to 100k devices</div>
              <div className='bullet'>Up to 25MB image size</div>
              <div className='bullet'>Unlimited active firmware versions</div>
              <div className='bullet'>$499/month (billed annually)</div>
            </div>
            <div className='cta'>
              <Link to='/ota?selection=Hosted Firmware Platinum'>
                <Button className='ctaButton p-button-raised'
                    label='Select'
                    icon='pi pi-arrow-right' iconPos='right'
                    onClick={() => this.setState({selection: 'Hosted Firmware Platinum'})}
                    />
              </Link>
            </div>
          </div>
        </div>
        <div className='optionWrapper p-col-12 p-sm-6 p-lg-3'>
          <div className='option'>
            <div className='optionHeader'>
              <div className='optionName'>Custom</div>
            </div>
            <div className='bullets'>
              <div className='bullet'>Managed security solution</div>
              <div className='bullet'>HTTPS API access</div>
              <div className='bullet'>Android & iOS clients</div>
              <div className='bullet'>Axle Key Vault or custom image signing</div>
              <div className='bullet'>Custom features possible for large accounts</div>
            </div>
            <div className='cta'>
              <Link to='/ota?selection=Hosted Firmware Custom'>
                <Button className='ctaButton p-button-raised'
                    label='Select'
                    icon='pi pi-arrow-right' iconPos='right'
                    onClick={() => this.setState({selection: 'Hosted Firmware Custom'})}
                    />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm(selection) {
    return (
      <iframe
          className="airtable-embed"
          src={`https://airtable.com/embed/shrfOQj4KoG3r6Svr?prefill_Selection=${selection}`}
          title="Form" />
    );
  }

  render() {
    return (
      <div className='page-firmwareota'>
        <div className='section'>
          <div className='subtitle'>
            {content.title}
          </div>
          <TextToParagraph text={content.description} />
        </div>
        <div className='image-section-wrapper'>
          <div className='section image'>
            <img src={content.image} alt='Firmware' />
          </div>
        </div>
        <div className='section'>
          <div className='p-grid cards'>
            {content.cards.map(card => (
              <div className='p-col-12 p-lg-6' key={card.title}>
                <Card
                    direction='horizontal'
                    header={card.title}
                    text={card.description} />
              </div>
            ))}
          </div>
          <div className='cta'>
            <Button className='ctaButton'
                label='Request a demo'
                icon='pi pi-star' iconPos='right'
                onClick={() => window.open(content.demoLink, '_blank')} />
            <Link to='/fleet'>
              <Button className='ctaButton'
                  label='Learn more'
                  icon='pi pi-arrow-right' iconPos='right' />
            </Link>
          </div>
          <Dialog
              header='Hosted Firmware Updates'
              visible={this.state.overlayVisible}
              modal={true}
              onHide={() => this.setState({overlayVisible: false})}>
            {this.renderDialog()}
          </Dialog>
        </div>
      </div>
    );
  }
}


export default FirmwareOta;
