import './About.scss';
import ParallaxImage from 'components/ParallaxImage';
import TextToParagraph from 'components/TextToParagraph';
import React from 'react';
import slide_pic_4 from 'img/slide_pic_4.png';
import {Button} from 'primereact/button';


const EMAIL = "hello@axle-medical.com";
const GOOGLE_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLScICYNm9thTcmi37cv6IvZ4YSfDiSzSRo6pldz9df58aeRjkw/viewform';


const CONTENT = {
  intro: {
    title: 'Our place in the world',
    text: [
      'Welcome to the connected health revolution: more devices revealing more insightful data, dramatically improving the quality of life for patients.',
      'As the revolution hits the mainstream, consumers expect devices that do more and cost less, whether in the hospital or in the home. At the same time, industry guidelines make it increasingly expensive to meet and exceed the bars for safety, reliability and data control. Device designers face this pressure from both sides.',
      "That's where we come in. Our team has launched at-home medical devices with companies big and small, and sought to improve on existing tools. The result is Axle Medical, bringing shorter launch timelines and reduced risk—a winning plan for a complex ecosystem.",
      'We want our partners to make a positive impact on the world with new devices to help millions of people. When you flourish, so do we.',
    ],

  },
  outro: {
    title: 'Stay connected',
    text: 'Subscribe to updates from Axle Medical, or drop us a line directly below.',
  },
};


const mailLink = jsx => {
  return (
    <a href={`mailto:${EMAIL}`} target='_blank' rel='noopener noreferrer'>
      {jsx}
    </a>
  );
};


function About() {
  return (
    <div className='page-about'>
      <div className='section'>
        <div className='subtitle'>
          {CONTENT.intro.title}
        </div>
        <TextToParagraph text={CONTENT.intro.text} />
      </div>
      <ParallaxImage src={slide_pic_4} heightPx={240} yOffsetPx={-120} />
      <div className='section'>
        <div className='subtitle'>
          {CONTENT.outro.title}
        </div>
        <TextToParagraph text={CONTENT.outro.text} />
        <div className='cta'>
          <Button
              className='mailingListButton'
              label='Subscribe'
              icon='pi pi-heart'
              iconPos='right'
              onClick={ () => window.open(GOOGLE_FORM, '_blank') }
              />
          {mailLink(
              <Button className='ctaButton'
                  label={EMAIL}
                  icon='pi pi-envelope' iconPos='right' />
          )}
        </div>
      </div>
    </div>
  );
}


export default About;
